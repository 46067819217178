.address-form-container {
    /* padding: 1rem 5rem; */
}

.shipping-address {
    text-align: center;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    padding-top: 17px;
    margin: 0;

}
.custom-dialog{
    width: 50vw;
}
.shipping_city-address {
    width : 48%;
}

.btn-close {
    border-radius: 100%;
    padding: 8px;
    color: white;
    opacity: 1;
    padding: 10px;

}

.close-btn-container {
    display: flex;
    justify-content: end;

}

.address-name-field {
    width: 100%;
}

.address_underline {
    height: 2px;
    width: 120px;
    background-color: #50ae31;
    margin: auto;
}

.state-select {
    /* width: 100%; */
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.address-field {
    width: 48%;
}

.address-field2 {
    width: 30%;
}

.state-select {
    width: 100%;
}

.city-address {
    width: 32%;
}

.address-input {
    width: 100%;
    display: block;
    background: #f2f3f4;
    border: none;
    padding: 18px;
    margin: 11px 0;
    box-sizing: border-box;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #9C9C9C;
}

.modal-body {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 50px;
}

.address-form {
    width: 100%;
}

.field-margin {
    margin: 9px 0;
}

.address-form label {
    font-size: 18px;
    font-weight: 500;
    /* font-family: "Inter"; */
    font-style: normal;
}

.address-submit-btn button {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    background: #004684;
    color: #FFFFFF;
    border: none;
    padding: 13px;
    margin-top: 15px;
    width: 100%;
}

.btn-group {
    color: #9C9C9C;
    background: #f2f3f4;
    width: 100%;
    margin: 11px 0;
}

.btn-group .btn {
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-group .dropdown-menu {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
}

.btn-group .dropdown-menu li:hover {
    background: #f2f3f4;

}

.dropup {
    position: relative;
}

.dropup-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.show-drop-up {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}

.hide-drop-up {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s linear;
}
.validation-color{
    color: red;
}

@media(max-width: 1025px) {
    .field-margin {
        flex-direction: column;
    }

    .address-field,
    .city-address {
        width: 100%;
    }

    .shipping-address {
        padding-top: 0;
    }

    .modal-body {
        padding: 14px 37px;
    }

    .address-input {
        padding: 9px;
        margin: 9px 0;
    }

    .address-form label {
        font-size: 17px;
    }
}

.modal-dialog {
    max-width: 100%!important;
    margin-top: 0;
}
@media(max-width: 426px) {
    .address-form label {
        font-size: 15px;
    }


    .modal-body {
        padding: 14px 20px;
    }


}
@media(max-width: 426px){
    .custom-dialog{
          
width: 90vw;
    }

}


