.thank-you-container {
    padding: 2.5rem 6rem 0 6rem;
}

/* Order confirmed section */
.order-msg-container {
    width: 40%;
}

.order-done-icon {
    color: white;
    background-color: #004684;
    padding: 8px;
    border-radius: 100%;
    font-size: 66px;
}

.confirmed-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #323232;
    margin: 11px 0;
}

.confirmed-msg {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 166.5%;
    text-align: center;
    color: #4B4B4B;
    margin: 0;
}

.continue-shopping-btn {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.022em;
    color: #FFFFFF;
    background-color: #004684;
    padding: 10px 15px;
    border-color: #004684;
    margin-top: 24px;
    transition: all 0.4s ease-in-out;
}
.continue-shopping-btn:hover{
  color: #004684;
  background: none;
}

/* Order Summary section */
.summary-outer-containner {
    width: 40%;
}

.order-summary-container {
    width: 100%;
    border: 1px solid black;

}

.order-product-container {
    padding: 12px;
}


.summary-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    padding: 17px;
    margin: 0;
}

.line {
    font: 2px;
    margin: 0;
}

.order-img-div {
    width: 78px;
    height: 80px;
    padding: 10px 20px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-detail {
    width: 70%;
}

.order-img-div img {
    width: 32px;
    height: 66px;
}

.ordered-product-name {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #004684;
    margin-bottom: 0;
}

.ordered-product-detail {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #50AE31;
}

.ordered-product-qty {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #575656;
    margin-bottom: 0;
}

.ordered-product-price {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #004684;
    margin-bottom: -5px !important;
}

.ordered-product-price p {
    margin: 0;
}

.subtotal-container {
    padding: 12px;
}

.subtotal {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #575656;
    margin: 0;
}

.subtotal-price {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #575656;
}

.total-amount-container {
    padding: 12px;
}

.total-amount {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
}

.total-amount-price {
    font-weight: 700;
}

.invoice-btn {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.022em;
    background-color: #004684;
    color: #FFFFFF;
    border-color: #004684;
    width: 100%;
    padding: 15px 0;
    margin-top: 28px;
    transition: color 0.4s ease-in-out;
}
.invoice-btn:hover{
    color: #004684;
    background: none;
}

@media(max-width: 1281px){
    .order-detail {
        width: 65%;
    }
}

/* Media query */
@media(max-width: 1024px) {
    .thank-you-container {
        padding: 2.5rem 3rem 0 3rem;
    }

    .summary-outer-containner {
        width: 50%;
    }

    .order-detail {
        width: 68%;
    }
}

@media(max-width: 913px){
    .order-detail {
        width: 60%;
    }
}

@media(max-width: 769px) {
    .thank-you-container {
        display: flex;
        flex-direction: column;
    }

    .order-msg-container {
        width: 100%;
    }

    .summary-outer-containner {
        width: 100%;
        margin-top: 26px;
    }
    .continue-shopping-btn:hover{
        color: white;
        background: #004684;
      }
      .invoice-btn:hover{
        color: white;
        background: #004684;
    }
}

@media(max-width: 426px) {
    .thank-you-container {
        padding: 40px 20px 0 20px;
    }

    .confirmed-heading {
        font-size: 25px;
    }

    .confirmed-msg {
        font-size: 14px;
    }

    .continue-shopping-btn {
        margin-top: 16px;
    }

    .order-detail {
        width: 61%;
    }

    .order-done-icon {
        font-size: 62px;
    }

    .ordered-product-name {
        font-size: 16px;
    }

    .ordered-product-detail {
        font-size: 12px;
    }

    .ordered-product-qty,
    .ordered-product-price,
    .subtotal,
    .subtotal-price,
    .total-amount {
        font-size: 15px;
    }

    .invoice-btn,
    .continue-shopping-btn {
        font-size: 14px;
    }
}

@media(max-width: 321px) {

    .confirmed-heading,
    .summary-heading {
        font-size: 20px;
    }

    .order-product-container {
        padding: 8px;
    }

    .ordered-product-detail {
        font-size: 11px;
    }

    .ordered-product-name {
        font-size: 14px;
    }

    .ordered-product-qty,
    .ordered-product-price,
    .subtotal,
    .subtotal-price,
    .total-amount,
    .confirmed-msg {
        font-size: 13px;
    }

    .total-amount-container,
    .summary-heading,
    .subtotal-container {
        padding: 8px;
    }

    .total-amount-container {
        align-items: center;
    }

    .total-amount {
        margin-bottom: 0;
    }

    .invoice-btn,
    .continue-shopping-btn {
        font-size: 12px;
        padding: 9px;
    }

    .order-done-icon {
        font-size: 56px;
    }
}