@import "primereact/resources/themes/saga-blue/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "primeicons/primeicons.css";


@font-face {
  font-family: "greatvibes";
  src: url(./../../public/assets/font/GreatVibes-Regular.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  /* background: white; */
}

/* Chemical free section */
.nm_home {
  /* About Section */
  /* Product Section */
  /* Certified By Section */
  /* Leaadership section */
  /* Events and Achievements Section */
  /*# sourceMappingURL=footer.css.map */
  /*# sourceMappingURL=footer.css.map */
}

.nm_home .chemical-free-image-container {
  position: relative;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 562px;
  /* background: url('./../../public/img/image49.jpg') no-repeat; */
  background-repeat: no-repeat;
  background-size: cover;
}

.nm_home .image-container {
  width: 100%;
}

.nm_home .content {
  position: absolute;
  content: "";
  top: 48%;
  /* z-index: 1;
	 */
  margin: auto;
  width: 100%;
}

.nm_home .chemical-free-btn {
  color: white;
  font-size: 1.3rem;
}

.nm_home .shop-now {
  background-color: #50ae31;
  border: none;
  /* font-family: "Inter";
	 */
  font-style: normal;
  transition: all 0.4s ease-in-out;
}

.nm_home .shop-now:hover {
  background-color: #004684;

}

.nm_home .about-btn {
  background-color: #004684;
  border: none;
  font-style: normal;
  transition: all 0.4s ease-in-out;
}

.nm_home .about-btn:hover {
  background-color: #50ae31;

}

.nm_home .chemical-free-heading {
  font-family: greatvibes;
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 90.52%;
  letter-spacing: 0.005em;
  color: #fff;
}

.organic-products h6 {
  font-family: greatvibes;
}

.nm_home .chemical-free-heading2 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
}

.nm_home .about-section {
  background: url('/public/img/bg-pattan1.png');
  background-color: #c4eec4;
  height: 70%;
  padding: 1rem 5rem;
}

.nm_home .about-section .section-margin {
  margin-bottom: 30px;
}

.nm_home .section-height {
  /* height: 66px; */
  padding-bottom: 10px;
}

.nm_home .about-section-img {
  width: 50%;
  margin: 0px 10px;
}

.nm_home .about-section-img img {
  width: 100%;
}

.nm_home .about-section-content {
  width: 50%;
}

.nm_home .about-section-content-p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 30px;
}

.nm_home .aboutUs-heading1 {
  font-family: greatvibes, cursive;
  /* margin-bottom: 9px; */
  margin: 10px 0;
  font-size: 50px;
  font-weight: 400;
}

.nm_home .aboutUs-heading2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 17px;
}

.nm_home .chemical-icon-div {
  width: 48px;
  height: 48px;
}

.nm_home .chemical-icon {
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 1px solid black;
  border-radius: 100%;
}

.nm_home .aboutus-sub-heading {
  font-weight: bold;
}

.nm_home .aboutus-btn {
  font-size: 1.6rem;
  background-color: black;
  font-weight: 600;
  padding: 2px 2.5rem;
  transition: all 0.4s ease-in-out;
}

.nm_home .aboutus-btn:hover {
  color: black !important;
  background: none;
}

.nm_home .card {
  background: var(--surface-card);
  padding: 5rem !important;
  margin-bottom: 0 !important;
  background: #004684;
}

.nm_home .p-carousel .p-carousel-indicators {
  padding: 0rem;
  margin-bottom: -3px;
  margin-top: -30px;
}

.nm_home .certified-section {
  margin: 4rem 5rem 0 5rem;
  padding: 20px;
  color: #004684;
  background: #FFF;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.certified-row {
  display: flex;
  justify-content: space-between;

}

.responsive-heading {
  display: none;
}

.certified-section .underline {
  width: 200px;
  height: 4px;
  background-color: #50ae31;
}

.certified-section .text-green {
  color: #50ae31 !important;
}

.certified-section .certified-heading {
  color: #000911;
  font-family: greatvibes;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.523%;
  letter-spacing: 0.25px;
  margin: 0;
}

.certified-section .certified-sub-heading {
  color: #000911;
  font-family: Inter;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.523%;
  letter-spacing: 0.225px;
  margin-top: 10px;
  margin-bottom: 0;
}

.certified-section .targedHeading {
  color: #50ae31 !important;
}

.nm_home .certified-section-content-p {
  color: #000911;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.523%;
  letter-spacing: 0.09px;
  margin: 15px 0 !important;
}

.certified-section .certified-section-content {
  width: 60%;
}

.nm_home .certified-logos {
  width: 30%;
}

.certified-sub-logos {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.certified-sub-logos img {
  width: 60%;
}

.certified-img-div {
  width: 56.96%;
  margin: auto;
}

.certified-logo-img {
  width: 100%;
}

.nm_home .leadership-container {
  padding: 5rem 5rem 0 5rem;
}

.nm_home .leadership-inner-container {
  width: 100%;
}

.nm_home .leadership-founder-detail {
  width: 50%;
}

.nm_home .labour-img-div {
  position: relative;
  top: 0;
}

.nm_home .founder-name-div {
  position: absolute;
  top: 238px;
  left: 142px;
  width: 238px;
  padding: 31px 26px;
  color: #fff;
  border-radius: 40px;
  background: #004684;
  background-image: url("/public/img/bg-pattan2.png");
  font-style: normal;
}

.nm_home .founder-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
}

.nm_home .about-founder {
  font-weight: 300;
  font-size: 12px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  opacity: 0.8;
}

.nm_home .founder-img {
  margin: 0px 15px;
}

.nm_home .founder-img img {
  border-radius: 22px;
  width: 100%;
}

.nm_home .labour-img {
  margin: 0px 15px;
}

.nm_home .leadership-founder-right-section {
  width: 50%;
  color: #004684;
}

.nm_home .leadership-heading {
  font-family: greatvibes;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 125.52%;
  color: #004684;
  letter-spacing: 0.005em;
}

.nm_home .leadership-underline {
  width: 99px;
  height: 3px;
  background: #50ae31;
}

.nm_home .leadership-founder-heading {
  /* font-family: "Inter";
	 */
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  padding-top: 10px;
}

.nm_home .leadership-detail {
  /* font-family: "Inter";
	 */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160.52%;
  letter-spacing: 0.005em;
  opacity: 0.9;
  width: 90%;
}

.nm_home .leardership-know-more-btn {
  background: #004684;
  border-radius: 11px;
  /* font-family: "Inter";
	 */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 156.52%;
  color: white;
  padding: 10px 40px;
  letter-spacing: 0.005em;
  border: none;
}

.nm_home .events-achievements-container {
  width: 100%;
  padding: 5rem 5rem 0 5rem;
  justify-content: space-evenly;
}

.nm_home .achievement-container {
  width: 30%;
  font-style: normal;
  color: #004684;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
}

.nm_home .achievement-img-container {
  width: 100%;
  height: 280px;
}

.nm_home .detail-container {
  padding: 20px;
}

.nm_home .achievement-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nm_home .achievement-heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  margin: 0;
}

.nm_home .achievement-detail {
  font-weight: 500;
  font-size: 16px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  margin: 14px 0;
}

.nm_home .achievements-more-btn {
  font-weight: 400;
  font-size: 20px;
  margin: 14px 0; 
  padding: 5px 24px;
  border-color: #004684;
  background-color: #004684;
  color: white;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
}

.nm_home .achievements-more-btn:hover {
  background: none;
  color: #004684;
  border: 2px solid #004684;
}

.nm_home .product_image_div {
  margin-right: 30px;
}

.nm_home .shadow-2 {
  width: 229px;
  height: 16rem;
}

.nm_home .product-carousel-card {
  padding: 16px 100px;
  background: #004684;
}

.nm_home .p-carousel-content {
  padding-top: 25px;
  margin-inline:-54px;
  margin-right:-73px
}

.nm_home .text-light {
  justify-content: space-between;
}

.nm_home .main_heading {
  font-family: greatvibes;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 0.5%;
  color: #fff;
  letter-spacing: 0.005em;
  margin-bottom: 0;
}

.nm_home .sub_heading {
  font-weight: 500;
  font-size: 38px;
  line-height: 40px;
  font-style: normal;
  color: #fff;
  letter-spacing: 0.005em;
  margin-bottom: 0;
}

.product-card-heading .underline{
  width: 200px;
  height: 4px;
  background-color: #50ae31;
  margin-left: 177px;
}

.nm_home .product_content {
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.5%;
  color: #fff;
  letter-spacing: 0.005em;
  width: 47.5%;
  margin: 5px auto;
}

.nm_home .product-card-heading {
  text-align: center;
}
.nm_home .product-card-heading p {
  font-size: 15px;
  color: white;
  width: 60%;
  margin: 20px auto 0 auto;
  font-weight: 400;
}
.nm_home .p-link {
  width: 14px !important;
  border-radius: 100% !important;
  height: 14px !important;
}

.nm_home .about-us-section .about_page_section_heading {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.nm_home .about-us-section .about_page_section_heading .leaf {
  position: absolute;
  top: 0;
  right: -20px;
}

.nm_home .about-us-section .overview_h5 {
  font-family: greatvibes;
  font-weight: 400;
  font-size: 45px;
  line-height: 56px;
  margin: 0;
}

.nm_home .about-us-section .leadership {
  padding-left: 100px;
}

.nm_home .about-us-page {
  padding: 65px 0 0 0;
  display: flex;
}

.nm_home .about-us-page .about-content-section {
  width: 67.8%;
  padding-left: 100px;
}

.nm_home .about-us-page .about-content-section .overview-section {
  padding-bottom: 50px;
}

.nm_home .about-us-page .about-content-section .overview-section .about_page_section_content {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.nm_home .about-us-page .about-section-image {
  width: 32.2%;
  padding-right: 67px;
}

.nm_home .about-us-page .about-section-image img {
  width: 100%;
}

.nm_home .text-light-product {
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
}

.methods input {
  width: 18px;
}

/* ******Media Query****** */
@media (max-width: 1440px) {

  .product-card-heading .underline{
    margin: auto;
    width: 183px;
  }

  .nm_home .leadership-inner-container {
    width: 80%;
    margin: auto;
  }

  .nm_home .labour-img {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .nm_home .product_image_div h4 {
    font-size: 16px;
  }

  .nm_home .aboutus-sub-heading {
    font-size: 20px;
  }

  .nm_home .labour-img img {
    width: 100%;
  }

  .nm_home .founder-img {
    width: 100%;
    height: auto;
  }

  .nm_home .founder-img img {
    width: 100%;
  }

  .nm_home .founder-name-div {
    top: 183px;
    left: 57px;
    width: 224px;
  }

  .nm_home .leadership-heading {
    font-size: 48px;
  }

  .nm_home .leadership-founder-heading {
    font-size: 41px;
  }

  .nm_home .leadership-detail {
    width: 100%;
  }
}

@media(max-width: 1281px) {
  .nm_home .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {

  /* NM india Section */
  .nm_home {
    /* Chemical free Section */
    /* About us Section */
    /* Leadership section */
    /* Achievement & Events Section */
  }

  .nm_home .nmindia-container {
    padding-left: 50px !important;
    padding-right: 0px;
  }

  .nm_home .logo-container {
    width: 30%;
  }

  .nm_home .address-container {
    width: 70%;
    justify-content: space-evenly !important;
  }

  .nm_home .nm-india-address {
    width: 45%;
  }

  .nm_home .chemical-free-heading {
    font-size: 50px;
    line-height: 71.52%;
  }

  .nm_home .chemical-free-image-container {
    width: 100%;
    height: 410px;
  }

  .nm_home .chemical-free-heading2 {
    font-size: 40px;
  }

  .nm_home .about-section {
    padding: 1rem 3rem;
  }

  .nm_home .aboutus-sub-heading {
    font-size: 1rem;
  }

  .nm_home .certified-section {
    margin: 3rem 3rem 0 3rem;
    color: #004684;
  }

  .nm_home .certified-sub-logos {
    margin-top: 20px;
  }

  .nm_home .certified-section-content-p {
    width: 100%;
    font-size: 16px;
  }

  .certified-section .certified-heading {
    font-size: 40px;
  }

  .certified-section .certified-sub-heading {
    font-size: 28px;
  }

  .nm_home .founder-name {
    font-size: 16px !important;
  }

  .nm_home .about-founder {
    font-size: 11px;
  }

  .nm_home .founder-name-div {
    padding: 20px 22px;
    top: 115px;
    left: 35px;
    width: 195px;
  }

  .nm_home .leadership-founder-heading {
    font-size: 30px;
  }

  .nm_home .leadership-heading {
    font-size: 35px;
  }

  .nm_home .leadership-detail {
    font-size: 12px;
  }

  .nm_home .leadership-container {
    padding: 3rem 3rem 0 3rem;
  }

  .nm_home .leadership-underline {
    width: 83px;
  }

  .nm_home .know-more-container {
    display: flex;
    justify-content: left;
  }

  .nm_home .achievement-img-container {
    height: 170px;
  }

  .nm_home .achievement-detail {
    font-size: 13px;
  }

  .nm_home .achievement-heading {
    font-size: 25px;
  }

  .nm_home .achievements-more-btn {
    font-size: 18px;
    margin: 14px 0;
  }

  .nm_home .detail-container {
    padding: 12px 20px;
  }

  .nm_home .events-achievements-container {
    padding: 3rem 3rem 0 3rem;
  }
  .nm_home .sub_heading {
    font-size: 35px;
  }
}

@media (max-width: 991px) {

  .nm_home .nmindia-container {
    padding: 0px !important;
  }

  .nm_home .chemical-free-heading {
    font-size: 40px;
    line-height: 71.52%;
  }

  .nm_home .chemical-free-heading2 {
    font-size: 30px;
  }

  .nm_home .about-section-content {
    width: 100%;
  }

  .nm_home .about-section-img {
    width: 100%;
    text-align: center;
  }

  .nm_home .about-section-img img {
    width: 90%;
  }

  .nm_home .aboutus-btn {
    font-size: 1.3rem;
  }

  .nm_home .about-content-container {
    display: flex;
    flex-direction: column;
  }

  .nm_home .leadership-container {
    flex-direction: column;
    padding-top: 3.5rem;
  }

  .nm_home .leadership-founder-detail {
    width: 100%;
  }

  .nm_home .founder-img {
    width: 42%;
    margin: 0px;
  }

  .nm_home .labour-img {
    width: 81%;
  }

  .nm_home .labour-img img {
    width: 100%;
  }

  .nm_home .founder-name-div {
    top: 155px;
    left: 81px;
    width: 199px;
  }

  .nm_home .leadership-founder-right-section {
    width: 100%;
  }

  .nm_home .achievement-heading {
    font-size: 21px;
  }

  .nm_home .achievement-img-container {
    height: 136px;
  }

  .nm_home .achievement-detail {
    font-size: 15px;
    /* text-align: justify;
     */
  }

  .nm_home .achievements-more-btn {
    font-size: 16px;
    margin: 8px 0;
  }
}

@media(max-width: 769px) {
  .nm_home .nav-link:hover {
    opacity: 1;
  }

  .responsive-heading {
    display: block;
    text-align: center;
  }

  .certified-section .underline {
    margin: auto;
    width: 200px;
    height: 4px;
  }

  .desktop-heading {
    display: none;
  }

  .nm_home .shop-now:hover {
    border: 2px solid #50ae31;
    color: white;
    background-color: #50ae31;
  }

  .nm_home .about-btn:hover {
    border: 2px solid #004684;
    color: white;
    background-color: #004684;
  }

  .nm_home .achievements-more-btn:hover {
    color: white;
    background: #004684;
  }

  .nm_home .aboutus-btn:hover {
    color: white !important;
    background: black;
  }

  .certified-row {
    flex-direction: column-reverse;

  }

  .certified-section .certified-section-content,
  .nm_home .certified-logos {
    width: 100%;
  }

  .certified-img-div {
    width: 28%;
    margin-top: 24px;
  }

  .certified-sub-logos img {
    width: 49%;
  }

  .certified-sub-logos {
    justify-content: space-evenly;
  }

}

@media(max-width: 541px) {
  .nm_home .section-height {
    height: 103px;
  }

  .nm_home .events-achievements-container {
    flex-direction: column;
  }

  .nm_home .events-achievements-container {
    padding: 0 20px;
  }

  .nm_home .achievement-container {
    width: 100%;
    text-align: center;
    margin-top: 32px;
  }

  .nm_home .achievement-img-container {
    height: 236px;
  }

  .nm_home .certified-logos {
    justify-content: center;
  }
}

@media (max-width: 525px) {
  .nm_home .product-carousel-card {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .nm_home .product-card-heading p  {
    width : 100%;
  }

  .nm_home .product_image_div {
    margin-right: 0;
    width: 80%;
    margin: 0 auto;
  }

  .nm_home .product_content {
    width: 100%;
  }

  .nm_home .main_heading {
    font-size: 40px;
    line-height: 30px;
  }

  .nm_home .sub_heading {
    font-size: 28px;
  }
}

@media(max-width: 426px) {

  .nm_home .nav-account {
    font-size: 12px;
  }

  .nm_home .mobile-search-container {
    width: 75%;
  }

  .nm_home .nav-link {
    font-size: 15px;
  }

  .nm_home .icons {
    padding: 8px 3px;
  }

  .nm_home .logo-container {
    width: 50%;
  }

  .nm_home .logo-img {
    width: 100%;
  }

  .nm_home .chemical-free-image-container {
    width: 100%;
    height: 290px;
  }

  .nm_home .chemical-free-heading {
    font-size: 35px;
    line-height: 71.52%;
  }

  .nm_home .chemical-free-heading2 {
    font-size: 25px;
  }

  .nm_home .image-container {
    height: 250px;
  }

  .nm_home .chemical-free-btn {
    font-size: 14px;
  }

  .nm_home .about-section-content-p {
    text-align: justify;
  }

  .nm_home .section-height {
    height: auto;
    margin: 12px 0px;
  }

  .nm_home .aboutus-sub-heading {
    font-size: 15px;
  }

  .nm_home .aboutUs-heading2 {
    font-size: 25px;
  }

  .nm_home .certified-section {
    margin: 32px 20px 0 20px;
  }

  .nm_home .certified-section-content-p {
    width: 100%;
    font-size: 15px;
    text-align: justify;
    margin: 15px 0 0 0 !important;
  }

  .nm_home .leadership-detail {
    text-align: justify;
  }

  .nm_home .certified-logos {
    margin-top: 0px;
  }

  .certified-img-div {
    margin-top: 2rem !important;
    width: 40%;
  }

  .certified-section .certified-heading {
    font-size: 35px;
  }

  .certified-section .certified-sub-heading {
    font-size: 20px;
  }

  .nm_home .aboutus-sub-heading-para {
    font-size: 12px !important;
  }

  .nm_home .about-section {
    padding: 16px 20px;
  }

  .nm_home .founder-img {
    width: 94%;
  }

  .nm_home .leadership-founder-heading {
    font-size: 25px;
  }

  .nm_home .founder-name {
    font-size: 13px !important;
    margin: 0;
  }

  .nm_home .about-founder {
    display: none;
  }

  .nm_home .founder-name-div {
    top: 114px;
    left: 41px;
    width: 149px;
    padding: 17px 25px;
    border-radius: 12px;
  }

  .nm_home .leadership-underline {
    width: 83px;
  }

  .nm_home .leadership-container {
    padding: 2.5rem 2rem 0 2rem;
  }

  .nm_home .leardership-know-more-btn {
    padding: 7px 35px;
  }

  .nm_home .know-more-container {
    display: flex;
    justify-content: center;
  }

  .nm_home .achievement-detail {
    text-align: justify;
  }

  .nm_home .achievement-btn-container {
    display: flex;
    justify-content: center; 
  }
  .nm_home .aboutus-sub-heading-para {
    text-align: center;
   }
}

@media (max-width: 375px) {
  .nm_home .labour-img {
    width: 78%;
  }

  .nm_home .founder-name-div {
    padding: 13px 25px;
  }

  .nm_home .know-more-container {
    justify-content: center;
  }

  .nm_home .footer-heading {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .nm_home {
    /* Leadership section */
    /* Achievement and events section */
  }

  .nm_home .welcome-bar {
    width: 60%;
  }

  .nm_home .nav-account {
    width: 40%;
  }

  .nm_home .mobile-search-container {
    width: 92%;
  }

  .nm_home .chemical-free-heading {
    font-size: 30px;
  }

  .nm_home .chemical-free-heading2 {
    font-size: 20px;
  }

  .nm_home .aboutUs-heading1 {
    font-size: 30px;
  }

  .nm_home .aboutUs-heading2 {
    font-size: 20px;
  }

  .nm_home .aboutus-sub-heading {
    font-size: 11px;
  }

  .certified-img-div {
    width: 50%;
    margin-top: 1rem !important;
  }

  .nm_home .leadership-heading {
    font-size: 30px;
  }

  .nm_home .leadership-founder-heading {
    font-size: 20px;
  }

  .nm_home .labour-img {
    width: 73%;
  }

  .nm_home .founder-img {
    width: 100%;
  }

  .nm_home .founder-name-div {
    top: 90px;
    left: 29px;
    padding: 5px 20px;
  }

  .nm_home .achievement-img-container {
    height: 171px;
  }
}

@media(max-width: 281px) {
  .nm_home .chemical-free-btn {
    font-size: 12px;
  }

  .nm_home .leadership-inner-container {
    width: 100%;
  }

}