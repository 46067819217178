.dashboard_container {
    display: flex;
    background: #FFF;
}

.dashboard_container .side_bar {
    flex: 1 1 auto;
    width: 20%;
    height: auto;
    background-color: #004684;
    margin: 2px 0 !important;
}


.dashboard_container .user_data_container {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
}

.dashboard_container .menu_list {
    list-style: none;
    padding: 20px 0;
}

.menu_list .menu_item {
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 18px;
    padding: 15px 0;
    margin-top: 10px;
    margin-right: 14px;
}

.menu_list .active_link {
    color: #363636;
    border-radius: 0px 33.5px 33.5px 0px;
    background: #E9EFFD;
}

.menu_item p {
    margin-bottom: 0;
}

.icons {
    margin: 0 14px;
    font-size: 25px;
}

/*Dashboard User data */
.dashboard_user {
    padding: 0 23px;
    height: 100%;
}

.dashboard_main_heading {
    color: #004684;
    font-size: 25px;
    font-weight: 700;
    margin: 30px 0;
}

.second_container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.second_container .order_heading {
    padding: 17px 20px;
    color: #061A00;
    font-size: 20px;
    font-weight: 600;
}

.horizontal_line {
    margin: 0;
}

.second_container .orders_container {
    width: 48%;
    border-radius: 7px;
    background: #FEFEFE;
    box-shadow: 2px 2px 11px 3px rgba(0, 0, 0, 0.10);
    padding-bottom: 25px;
}

.second_container .address_container {
    width: 48%;
    border-radius: 7px;
    background: #FEFEFE;
    box-shadow: 2px 2px 11px 3px rgba(0, 0, 0, 0.10);
    position: relative;
}

.address_cards {
    height: 630px;
    overflow-y: auto;
}

.card {
    padding: 20px 20px 0 20px;
}

.second_container .sub_heading {
    color: #004684;
    font-size: 18px;
    font-weight: 600;
    line-height: 156.523%;
    letter-spacing: 0.09px;
}

.second_container .card_content {
    color: #575656;
    font-size: 15px;
    font-family: Inter;
    line-height: 156.523%;
    letter-spacing: 0.075px;
    width: 80%;
}

.p-progressbar .p-progressbar-value {
    background: #004684 !important;
}

.view_button {
    background: #004684;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.33px;
    border-color: #004684;
    margin: 24px;
    padding: 10px 33px;
    border: none;
}

.view_button_container {
    text-align: right;
    align-items: end;
    position: absolute;
    bottom: 0;
    right: 0;
}

.dashboard_user .order_status {
    display: flex;
    justify-content: space-between;
    margin: 35px 0px;
}

.order_completion_rate {
    display: flex;
    align-items: center;
    width: 31%;
    background: #FEFEFE;
    border-radius: 7px;
    box-shadow: 2px 2px 11px 3px rgba(0, 0, 0, 0.10);
    padding: 40px 26px;
}

.dashboard_user .status_title {
    color: #061A00;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 11px;
}

.dashboard_user .status_description {
    color: #000;
    font-size: 14px;
}

.progressBar {
    margin: 17px 17px 17px 0;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #004684 !important;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: black !important;

}

.dashboard_user .order_status_name {
    color: #757678;
    font-size: 20px;
    line-height: 156.523%;
    letter-spacing: 0.09px;
    margin: 0;
}

.dashboard_user .status_dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #50ae31;
    margin: 0 10px;
}

.dashboard_user .dispatch_div {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.ordered_product_div {
    background-color: #FFF;
    box-shadow: 2px 2px 11px 3px rgba(0, 0, 0, 0.10);
    padding: 22px 18px;
    display: flex;
    justify-content: space-between;
}

.ordered_product_div .order-img-div {
    width: 112px;
    height: 116px;
}

.order-img-div img {
    width: 67%;
    height: auto;
}

.dashboard_user .ordered_product {
    display: flex;
    justify-content: space-between;
    width: 70%;
    background-color: #F5F6F8;
    padding: 23px;
}

.dashboard_user .product_name {
    font-size: 18px;
}


.order_tracking_detail {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.order_tracking {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.tracking_btn {
    background-color: #004684;
    color: white;
    padding: 6px 15px;
    margin: 0;
    text-align: center;
    display: inline;
    border: none;
}

.view_detail,
.invoice {
    margin: 0;
    font-size: 16px;
    color: #575656;
    text-align: center;
}

.burger-icon {
    display: none;
}

.cross-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 23px;
    color: #022e55 !important;
}

.close_sidebar {
    display: none;
}

.order_id {
    color: black;
}

/* Media query */
@media(max-width: 1441px) {
    .order_completion_rate {
        padding: 13px 12px;
    }

    .tracking_btn {
        padding: 6px 10px;
    }
}

@media(max-width:1025px) {
    .dashboard_container {
        position: relative;
    }

    .second_container {
        flex-direction: column;
    }


    .dashboard_user .order_status {
        margin-top: 20px;
        width: 100%;
    }

    .order_completion_rate {
        flex-direction: column;
        width: 32%;
    }

    .burger_container {
        display: flex;
        margin-top: 20px;

    }

    .second_container .orders_container,
    .second_container .address_container {
        width: 100%;
        margin: 10px 0;

    }

    .view_button_container {
        position: static;
    }


    .burger-icon {
        display: block;
        margin: 0 10px;
        font-size: 30px;
        color: #004684;
    }

    .menu_list .menu_item {
        font-size: 16px;
    }


    .progressBar {
        width: 39%;
        margin: auto;
    }

    .dashboard_user .status_title {
        font-size: 14px;
        margin: 7px 0px;
        text-align: center;
    }

    .dashboard_user .status_description {
        font-size: 12px;
    }

    .second_container .order_heading {
        padding: 15px 18px;
        font-size: 18px;
    }

    .card {
        padding: 7px 15px 0 15px;
    }

    .second_container .card_content,
    .tracking_btn,
    .view_detail,
    .invoice {
        font-size: 12px;
    }

    .second_container .sub_heading {
        font-size: 15px;
    }

    .dashboard_user .ordered_product {
        padding: 11px;
    }

    .ordered_product_div {
        padding: 12px;
    }
}

@media(max-width:769px) {

    .side_bar {
        display: none;
    }

    .dashboard_container .user_data_container {
        width: 100%;
    }

    .dashboard_user .status_description {
        font-size: 14px;
        margin: 0;
    }

    .CircularProgressbar {
        width: 90% !important;
    }

    .second_container {
        flex-direction: column;
    }

    .second_container {
        margin: 10px 0;
    }

    .order_tracking_detail {

        padding: 11px;
    }
}

@media(max-width: 426px) {
    .dashboard_user .order_status {
        flex-direction: column;
    }

    .order_completion_rate {
        width: 100%;
        margin-top: 10px;
    }

    .dashboard_main_heading {
        margin: 22px 0;
    }

    .dashboard_user {
        padding: 0 20px;
    }

    .order_tracking_detail {
        width: 30%;
    }

    .order_tracking {
        width: 100%;
    }

    .view_button {
        font-size: 14px;
        margin: 24px;
        padding: 4px 23px;
    }

    .ordered_product_div {
        padding: 0;
    }

    .dashboard_user .ordered_product {
        display: block;
    }


    .dashboard_user .ordered_product,
    .order_tracking_detail {

        width: 100%;
    }

    .order_tracking {
        flex-direction: row;
    }
}