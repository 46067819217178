.product_detail {
  display: flex;
  justify-content: space-between;
}
.product_detail .first_section {
  width: 25.45%;
}
.product_detail .first_section .search {
  height: 34px;
  display: flex;
  margin-bottom: 10px;
}
.product_detail .first_section .search .search-box {
  border: 1px solid #898989;
  width: 83.7%;
  outline: none;
  height: 100%;
}
.product_detail .first_section .search .search-white {
  background-color: #004684;
  width: 15.38%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
}
.product_detail .first_section .search .search-white img {
  margin-top: -5px;
}
.product_detail .filter-heading h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 156.52%;
  /* identical to box height, or 47px */
  margin-bottom: 20px;
  letter-spacing: 0.005em;
}
.product_detail .filter-heading ul {
  padding-left: 1.5rem;
}
.product_detail .filter-heading ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 156.52%;
  /* or 31px */
  color: #575656;
  letter-spacing: 0.005em;
  border-bottom: 1px solid #898989!important;
  list-style-type: circle;
  padding-bottom: 5px;
}
.product_detail .filter-heading ul li:last-child {
  border-bottom: none;
}
.product_detail .second-section {
  padding-left: 0;
  width: 52.35%;
  display: flex;
}
.product_detail .second-section .div_background {
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  width: 32.5%;
  height: 72.3%;
}
.product_detail .second-section .div_background .product_img {
  height: 208px;
}
.product_detail .second-section .div_background .product_img img {
  width: 100%;
  height: 100%;
}
.product_detail .second-section .product_details {
  width: 62.5%;
  padding-left: 77px;
}
.product_detail .second-section .product_details h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  margin-bottom: 0;
  color: #004684;
}
.product_detail .second-section .product_details .tag_line {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 156.52%;
  /* identical to box height, or 16px */
  margin-bottom: 5px;
  letter-spacing: 0.005em;
  color: #50ae31;
}
.product_detail .second-section .product_details .price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #004684;
}
.product_detail .second-section .product_details .description {
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid #898989;
  border-bottom: 1px solid #898989;
  line-height: 15px;
  padding: 20px 0;
  color: #9c9c9c;
  margin-bottom: 20px;
  line-height: 156.52%;
}
.product_detail .second-section .product_details .location {
  font-weight: 500;
  font-size: 15px;
  line-height: 156.52%;
  /* identical to box height, or 19px */
  margin-bottom: 10px;
  letter-spacing: 0.005em;
  color: #000911;
}
.product_detail .second-section .product_details .pincode {
  border: 1px solid #898989;
  height: 34px;
  width: 128px;
  margin-bottom: 30px;
  padding-left: 5px;
}
.product_detail .second-section .product_details .pincode:focus-within {
  outline: none;
}
.product_detail .second-section .product_details .decrease_button,
.product_detail .second-section .product_details .increase_button,
.product_detail .second-section .product_details .cart_count {
  border: none;
  background-color: transparent;
}
.product_detail .second-section .product_details .cart_count {
  width: 30.5%;
  text-align: center;
}
.product_detail .second-section .product_details .cart_count:focus-within {
  outline: none;
}
.product_detail .second-section .product_details .buttons {
  border: 1px solid #898989;
  width: 20%;
  height: 34px;
  display: flex;
  justify-content: space-around;
  margin-right: 24px;
}
.product_detail .second-section .product_details .add_to_cart {
  background-color: #004684;
  color: white;
  border: none;
  padding: 10px 40px;
  height: 34px;
  display: flex;
  align-items: center;
}
.product_detail .second-section .product_details .add_to_cart:focus-within {
  outline: none;
}
.product_detail .second-section .product_details .check_button {
  background: #f7f7f7;
  border: none;
  height: 35px;
  outline: none;
}
.product_detail .second-section .product_details .check_button:hover {
  background-color: #004684;
  color: white;
}

.detail-padding {
  padding: 50px 100px 0;
}

.related_product .related_product_heading {
  width: -moz-fit-content;
  width: fit-content;
}
.related_product .related_product_heading h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  color: #061a00;
  border-bottom: 2px solid #50ae31;
}
.related_product .organic-products-details {
  margin-top: 20px;
}
.related_product .organic-products-details h6 {
  font-family: Great Vibes;
  font-weight: 400;
  font-size: 35px;
  line-height: 50px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
}
.related_product .organic-products-details h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
}
.related_product .organic-products-details .product-para {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
  width: 80%;
  margin: auto;
}
.related_product .organic-products-details .border-bottom {
  border-bottom: 1px solid #50AE31 !important;
  width: 150px;
  height: 3px;
  margin: auto;
}
.related_product .organic-products-details .product-card {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}
.related_product .organic-products-details .product-card .inner-cards {
  width: 19.7%;
}
.related_product .organic-products-details .product-card .inner-cards .card_img {
  border-radius: 100%;
}
.related_product .organic-products-details .product-card .inner-cards .card_img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.related_product .organic-products-details .product-card .inner-cards .storage-content {
  width: 95%;
  margin: 20px auto;
}
.related_product .organic-products-details .product-card .inner-cards .storage-content p {
  text-align: center;
  color: #004684;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}
.related_product .organic-products-details .border-bottom-card {
  border: 1px solid #000000;
}
.related_product .organic-products-details .product-list .col-lg-3 {
  margin-top: 36px;
}
.related_product .organic-products-details .product-list .product-list-card {
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
}
.related_product .organic-products-details .product-list .product-list-card .product-image {
  padding-top: 20px;
  width: 10rem;
  height: 14rem;
  margin: auto;
}
.related_product .organic-products-details .product-list .product-list-card .product-image img {
  width: 100%;
  height: 100%;
}
.related_product .organic-products-details .product-list .product-list-card .product-content {
  padding: 0 20px 20px;
}
.related_product .organic-products-details .product-list .product-list-card .product-content p {
  margin-bottom: 0;
}
.related_product .organic-products-details .product-list .product-list-card .product-content p.product_name {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #004684;
}
.related_product .organic-products-details .product-list .product-list-card .product-content p.product_tag_line {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #50AE31;
  max-width: 90%;
  width: 100%;
  overflow-wrap: anywhere;
  min-height: 40px;
}
.related_product .organic-products-details .product-list .product-list-card .product-content button {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #004684;
  border: 1px solid #004684;
}
.related_product .organic-products-details .product-list .product-list-card .product-content .product_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.related_product .organic-products-details .product-list .product-list-card .product-image-border {
  border: 1px solid #004684;
  margin: 0 20px;
}
.variant_btn_section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.variant_btn_section .btn_div {
  margin-right: 10px;
}
.variant_btn_section .btn_div button:focus {
  outline: none;
}
.variant_btn_section .btn_div button {
  background: #004684;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  transition: all 0.3s ease;
}
.focusVariant {
  background: transparent!important;
  border: 1px solid #004684!important;
  color: #004684!important;
  box-shadow: #004684 0px 0px 10px 0px!important;
}

@media (max-width: 1440px) {
  .product_detail .second-section {
    padding-left: 0;
  }
  .related_product .organic-products-details .product-list .product-list-card .product-content p.product_name {
    font-size: 13px;
  }
}
@media (max-width: 1080px) {
  .detail-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .detail-padding .filter-heading h4 {
    font-size: 25px;
  }
  .detail-padding .first_section {
    width: 35%;
  }
  .detail-padding .second-section {
    padding-left: 0;
    width: 58.35%;
  }
  .detail-padding .second-section .div_background {
    height: 55.3%;
  }
  .detail-padding .second-section .div_background .product_img {
    height: 150px;
  }
  .detail-padding .second-section .product_details {
    padding-left: 20px;
  }
  .detail-padding .second-section .product_details .add_to_cart {
    padding: 10px 20px;
  }
  .organic-products-details {
    margin-top: 50px;
    padding: 0;
  }
  .organic-products-details h6 {
    font-family: Great Vibes;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }
  .organic-products-details h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }
  .organic-products-details .product-para {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
    width: 95%;
    margin: auto;
  }
  .organic-products-details .border-bottom {
    border-bottom: 1px solid #50AE31 !important;
    width: 150px;
    height: 3px;
    margin: auto;
  }
  .organic-products-details .product-card {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  .organic-products-details .product-card .inner-cards {
    width: 19.7%;
  }
  .organic-products-details .product-card .inner-cards .card_img {
    border-radius: 100%;
  }
  .organic-products-details .product-card .inner-cards .card_img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  .organic-products-details .product-list .product-list-card .product-content {
    padding: 0 20px 20px;
  }
  .organic-products-details .product-list .product-list-card .product-content p {
    margin-bottom: 0;
  }
  .organic-products-details .product-list .product-list-card .product-content p.product_name {
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    color: #004684;
  }
}
@media (max-width: 525px) {
  .detail-padding {
    display: block;
    padding: 20px 32px;
  }
  .detail-padding .first_section,
  .detail-padding .second-section {
    width: 100%;
    display: block;
  }
  .detail-padding .first_section .search .search-box,
  .detail-padding .second-section .search .search-box {
    width: 320px;
  }
  .detail-padding .first_section .div_background,
  .detail-padding .second-section .div_background {
    width: 100%;
    padding: 20px;
  }
  .detail-padding .first_section .div_background .product_img,
  .detail-padding .second-section .div_background .product_img {
    height: 208px;
  }
  .detail-padding .first_section .product_details,
  .detail-padding .second-section .product_details {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
  .organic-products-details {
    margin-top: 50px;
    padding: 0;
  }
  .organic-products-details h6 {
    font-family: Great Vibes;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }
  .organic-products-details h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }
  .organic-products-details .product-para {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
    width: 95%;
    margin: auto;
  }
  .organic-products-details .border-bottom {
    border-bottom: 1px solid #50AE31 !important;
    width: 150px;
    height: 3px;
    margin: auto;
  }
  .organic-products-details .product-card {
    margin: 30px 0;
    display: block;
  }
  .organic-products-details .product-card .inner-cards {
    width: 60%;
    margin: 0 auto;
  }
  .organic-products-details .product-card .inner-cards .card_img {
    border-radius: 100%;
  }
  .organic-products-details .product-card .inner-cards .card_img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  .organic-products-details .product-card .inner-cards .storage-content {
    margin: 10px auto 20px 0;
  }
  .organic-products-details .product-list .product-list-card .product-content {
    padding: 0 20px 20px;
  }
  .organic-products-details .product-list .product-list-card .product-content p {
    margin-bottom: 0;
  }
  .organic-products-details .product-list .product-list-card .product-content p.product_name {
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    color: #004684;
  }
}/*# sourceMappingURL=productDetail.css.map */