/* .nm_header {
  position: sticky;
  top: 0;
  z-index: 10;
} */

.nm_header #top-bar {
  padding: 0 100px;
  box-sizing: border-box;
  background-color: #50ae31;
  color: white;
}

.nm_header .cart_count {
  background: white;
  color: black;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.nm_header #top-bar .welcome-bar {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.nm_header .nav-account .burger-btn {
  background-color: #50ae31;
  border: none;
  color: white;
}

.burger-btn a{
  color: white;
}
.nm_header .nav-account .burger-btn .burger-icon {
  width: 30px;
  height: 30px;
}

.nm_header .burger-btn .account-icon {
  font-size: 20px;
}

.nm_header .nm-header .navbar {
  padding: 6px 78px;
}

.nm_header .search {
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 10px;
}

.nm_header .p-inputtext {
 width: 100% !important;
}

.nm_header .nav-search-icon {
  background: #50ae31;
  color: white;
  right: 0px;
  height: 100%;
  width: 20%;
  z-index: 999;
}

.nm_header .nmindia-container {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  margin: 5px 0;
}

.nm_header .logo-container .logo-img {
  width: 100%;
  height: auto;
}

.nm_header .nmindia-container .logo-container {
  width: 25%;
}

.nm_header .nmindia-container .address-container {
  width: 77%;
  display: flex;
  justify-content:space-evenly;
}

.nm_header .address-container .nm-india-address {
  width: 30.5%;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #575656;
}

.nm_header .nm-india-address .order-margin {
  display: flex;
  margin: 6px 0 !important;
}

.nm_header .icon-color {
  color: #50ae31;
  margin: auto 8px;
  font-size: 20px;
}

.nm_header .toll-free-no {
  font-size: 12px;
}

.nm_header .font-size {
  font-size: 12px;
  margin-bottom: 0;
}

.nm_header .nav-link {
  font-size: 16px;
}

.nm_header .nav-link:hover {
  opacity: 0.8;
}

.nm_header .account:hover {
  opacity: 0.8;
}

.nm_header .nm-header .search-container {
  height: 35px;
  position: relative;
  left: 31px;
  width: 22%;
  
}

.navbar-menues {
  background: #004684;
  height: 59px;
}

.navbar-menues ul {
  display: flex;
  padding: 0 100px;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.navbar-menues ul li {
  margin-right: 30px;
  text-align: center;
  line-height: 20px;
}

.navbar-menues ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

/*# sourceMappingURL=header.css.map */


.navbar-menues {
  background: #004684;
  height: 59px;
}

.navbar-menues ul {
  display: flex;
  padding: 0 100px;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.navbar-menues ul li {
  margin-right: 30px;
  text-align: center;
  line-height: 20px;
}

.navbar-menues ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.navbar-menues #bi-search {
  font-size: 23px;
  height: 50px;
  display: block;
  cursor: pointer;
}

.navbar-menues .search-container {
  height: 35px;
  position: relative;
  left: 68px;
  width: 22%;

}

.navbar-menues .mobile-search-container {
  width: 50%;
  position: relative;
  /* right: 9px; */
  height: 33px;
  left: 7px;
}

@media (max-width: 1440px) {
  .navbar-menues ul li {
    margin-right: 25px;
    line-height: 20px;
  }

  .navbar-menues .search-container {
    left: 15px;
  }

  .navbar-menues ul li a {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .navbar-menues ul {
    padding: 0 50px;
  }

  .navbar-menues ul li a {
    font-size: 16px;
  }

  .navbar-menues #bi-search {
    height: auto;
    font-size: 18px;
  }
  .navbar-menues .search-container {
    width: 18%;
}
  .nm_header .nav-search-icon {
    /* right: -27px; */
    z-index: 999;
    position: relative;
}
.navbar-menues .search-container {
  left: 0px;
  justify-content: start !important;
}
  .sideBar {
    position: absolute;
    background: whitesmoke;
    height: 50%;
    width: 90%;
    left: 0;
    top: 40px;
    bottom: 0;
    z-index: 999;
    /* display: none; */
  }

  .sideBar ul {
    list-style: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sideBar .logo-container {
    width: 60%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .sideBar .logo-container img {
    width: 100%;
  }

  .sideBar .nav-menu-item {
    font-size: 18px;
    padding: 10px 0;
  }

  .sideBar .nav-menu-item .nav-menu-link {
    color: #fff;
    text-decoration: none;
  }

  .sideBar .nav-search-icon {
    height: 10%;
  }

  .sideBar .search {
    width: 100%;
    height: 37px;
    border: none;
  }

  .sideBar .mobile_search_bar {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .sideBar .mobile_search_bar button {
    border: none;
    background: #50ae31;
    color: #fff;
    width: 40px;
    font-size: 18px;
    height: 37px;
  }

  .sideBar .second-section {
    background: #022e55;
    height: auto;
  }

  .sideBar .contact-icons {
    margin-top: 30px;
    padding-left: 0;
  }

  .sideBar .contact-icons a {
    color: #50ae31;
    font-size: 25px;
  }
}

/*# sourceMappingURL=header.css.map */

/* Media query */
@media (max-width: 1024px) {

  /* Welcome */
  .nm_header {
    /* Navbar */
    /* NMIndia logo section */
  }

  .nm_header #top-bar {
    padding-left: 32px;
  }

  .nm_header .nm-header .navbar {
    padding-right: 0px !important;
    padding-left: 50px;
  }

  .nm_header .account {
    font-size: 13px;
  }

  .nm_header .nmindia-container {
    padding-left: 50px !important;
    padding-right: 0px;
  }

  .nm_header .nmindia-container .logo-container {
    width: 30%;
  }

  .nm_header .nmindia-container .address-container {
    width: 70%;
    justify-content: space-evenly !important;
  }

}

@media (max-width: 991px) {
  .nm_header {
    /* NMindia logo section */
  }

  .nm_header #top-bar .nav-account {
    font-size: 12px;
  }

  .nm_header .nav-account .burger-btn {
    width: auto;
    justify-content: end;
    padding: 0;
  }
  
  .nm_header .nav-account .burger-btn:focus-within{
    
    border: none;
    outline: none;
  }

  .nm_header .nav-account.burger-icon {
    width: 20px;
    height: 20px;
  }

  .nm_header .nmindia-container {
    padding: 0px !important;
  }

  .nm_header .logo-container .logo-img {
    width: 88%;
  }

  .nm_header .nmindia-container .logo-container {
    width: 50%;
    margin: auto;
  }

  .nm_header .nmindia-container .address-container {
    display: none;
  }

}

@media(max-width: 769px) {
  .navbar-menues {
    display: none;
  }
  .nm_header #top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
  }


  .nm_header {
    potion: static;

  }
  .menuIcon{
    transform: rotate(180deg);
  }
  .user_panel{
    position: relative;
  }
  .user_panel_hide{
    display: none;
  }

  .user_panel_show{
    display: block;
    font-size: 15px;
    padding: 10px !important;
    padding-left: 50px !important;
  }
  .panel_item{
    color: white;
    padding-top: 11px;
  }

  .show-navbar{
    visibility: visible;
    
  }
  .hide-navbar{
    visibility: hidden;
  
  }
}

@media(max-width: 426px) {
  .nm_header {
    /* NMIndia logo section */
  }

  .nm_header .nav-account .burger-btn .burger-icon {
    width: 20px;
    height: 20px;
  }

  .nm_header #top-bar {
    padding: 0 20px;
  }

  .nm_header #top-bar .nav-account {
    font-size: 12px;
  }

  .nm_header .nav-account.burger-btn {
    margin-left: 2px;
  }

  .nm_header .logo-container .logo-img {
    width: 100%;
  }

  .nm_header .nmindia-container .logo-container {
    width: 50%;
  }

}

@media (max-width: 320px) {

  .nm_header #top-bar {
    padding: 0 10px;
  }

  .nm_header #top-bar .nav-account {
    font-size: 12px;
  }

  .nm_header .nav-account.burger-btn {
    margin-left: 0px;
  }

}