.blog-detail-page {
    padding: 50px 100px 0 100px;
}

.blog-detail-page .blog-heading {
    color: #323232;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 24px 0;
}

.blog-detail-page .blog-detail {
    color: #061A00;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 156.523%;
    letter-spacing: 0.1px;
}

.blog-detail-page .blog-image {
    text-align: center;
}

.blog-detail-page .tagFields {
    display: flex;
    width: 100%;
}

.blog-detail-page  input {
    background: #F4F4F4;
    color: #9C9C9C;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    padding: 15px;
    margin-right: 30px;
    width: 35%;
}

.blog-detail-page  input:focus{
    border: none;
    border-width: 0px;
}

.blog-detail-page .tag-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.blog-detail-page .tag {
    color: #323232;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: white !important;
    margin-right: 30px;
    padding: 0;
}

.reply-container {
    margin-top: 70px;
}

.blog-detail-page .leave-reply {
    width: 100% !important;
    margin-bottom: 16px;
}

.blog-detail-page .reply-container p {
    color: #061A00;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 156.523%;
    letter-spacing: 0.1px;
}

.blog-detail-page .submit-btn-container {
    justify-content: start !important;
}

.blog-detail-page .contact-field {
    padding: 0;
}

@media(max-width: 1024px) {
    .blog-detail-page {
        padding: 50px 50px 0 50px;
    }

    .blog-detail-page .blog-heading,
    .blog-detail-page .tag {
        font-size: 30px;
    }

    .blog-detail-page .blog-detail,
    .blog-detail-page .reply-container p {
        font-size: 16px;
    }
    .blog-detail-page input {
        width: 50%;
    }
}

@media(max-width:768px) {
    .blog-detail-page .tagFields input {
        width: 50%;
    }

    .blog-detail-page .tagFields {
        width: 100%;
    }
    .blog-detail-page input {
        font-size: 13px;
    }
}

@media(max-width: 541px) {
    .blog-detail-page {
        padding: 20px 20px 0 20px;
    }

    .blog-detail-page .blog-detail {
        text-align: justify;
    }

    .blog-detail-page .blog-heading,
    .blog-detail-page .tag {
        font-size: 22px;
    }

    .blog-detail-page .form-container .form-input {
        padding: 16px;
    }

    .blog-detail-page .tagFields {
        flex-direction: column;
    }

    .blog-detail-page .tagFields input {
        width: 100%;
        margin-bottom: 15px;
    }

    .blog-detail-page .contact_field {
        margin-bottom: 15px;
    }

    .blog-detail-page .form-container .form-input,
    .file-input {
        margin: 0px !important;
    }
    
    .blog-detail-page .tag-container {
        display: block
       
    }
    .blog-detail-page .blog-detail, .blog-detail-page .reply-container p {
        font-size: 15px;
     line-height: 143.523%;
    }
}