.blog-container {
    position: relative;
    display: flex;
    padding: 3.5rem 5rem 0 5rem;
    height: 100%;
}

.blog-container .blog-menu {
    position: sticky;
    top: 25px;
    width: 25%;
    height: fit-content;
}

.blog-container .blogs {
    width: 75%;
    margin-left: 35px;
    justify-content: space-between;
    overflow: scroll;

}

.blog-search-container {
    position: relative;
}

.blog-menu .blog-search {
    width: 100%;
    padding: 10px;
    border: 0.5px solid rgb(240, 234, 234);
    margin-bottom: 35px;

}

.blog-menu .blog-search-icon {
    position: absolute;
    right: 11px;
    top: 10px;
    font-size: 27px;
    color: rgb(146, 145, 145);
}

.blog-category-heading {
    margin-top: 0;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
}

.blog-menu-line {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.blog-list {
    list-style: none;
    padding: 0;
}

.blog-list li {
    padding: 10px 0;
    width: auto;
    font-weight: 600;
    color: black;
    transition: color 0.4s ease-in-out;
}

.blog-list li:hover {
    color: #50ae31;
}

.blog-menu-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.blog-menu-img {
    width: 23%;
    height: 100px;
}

.blog-menu-img img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}


.blog-post {
    padding-left: 18px;
    width: 77%;
}

.blog-post .blog-date {
    font-size: 14px;
    color: rgb(146, 145, 145);
    margin-bottom: 10px;
}

.blog-post .blog-detail {
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    color: black;
    transition: color 0.4s ease-in-out;
}

.blog-post .blog-detail:hover {
    color: #50ae31;
}

.blogs .blog-post-img {
    position: relative;
    width: 100%;
    height: 378px;
    border-radius: 20px;
    overflow: hidden;
}

.blogs .img-link-heading {
    position: absolute;
    right: 18px;
    top: 18px;
    border: none;
    border-radius: 12px;
    padding: 4px 12px;
    z-index: 9;
    color: white;
    background-color: #50ae31;
    transition: background-color 0.4s ease-in-out;
}

.blogs .img-link-heading:hover {
    color: #50ae31;
    background-color: white;
}

.img-dark-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    content: '';
    z-index: 1;
    transition: opacity 0.4s ease-in-out;
    background: rgba(31, 30, 30, 0.65);
    cursor: pointer;
    border-radius: 7px;
}

.blog-post-img:hover .img-dark-hover {
    opacity: 1;
}

.blogs .blog-post-img img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
}

.blog-post-img:hover.blog-post-img img {
    transform: scale(1.3);
}

.blogs .blog-post-detail {
    margin: 20px 0;
}

.posted-blog {
    width: 48%;
    padding-bottom: 30px;
}

.blog-post-detail-p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
    letter-spacing: 2px;
}

.blog-post-detail-p p {
    margin-bottom: 0;
    margin: 0 8px;
}

.date-line {
    height: 2px;
    width: 90px;
    background-color: #004684;
    margin-left: 20px;
    margin: 0;
}

.blog-post-detail-title {
    font-size: 26px;
    font-weight: 700;
    margin: 10px 0;
}

.about-blog-post {
    font-size: 16px;
    line-height: 166.5%;
}

.blog-read-more-btn {
    padding: 10px 27px;
    border: none;
    background-color: #004684;
    color: white;
    transition: background-color 0.4s ease-in-out;
}

.blogs .blog-read-more-btn:hover {
    background-color: #50ae31;
}

.modal {
    position: relative;
    opacity: 1;
    z-index: 9;
}

/* BLog detail pop-up */
.blog-detail-container {
    position: absolute;
    bottom: 0px;
    z-index: 10;
    right: 30%;
    width: 40%;
    margin: auto;
    padding: 20px;
    background-color: beige;
}

.blog-detail-container .blog-detail-img {
    width: 100%;
    margin: auto;
}

.blog-detail-img img {
    width: 100%;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.close-blog-btn {
    position: absolute;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
}

.detail-popup {
    margin: 32px 0px;
}

.accordian-container {
    display: none;
}

/* Media Query */
@media(max-width:1441px) {
    .blogs .blog-post-img {
        height: 317px;
    }

    .blog-menu-img {
        width: 37%;
        height: 90px;
    }
}

@media(max-width: 1024px) {

    .blog-container {
        padding: 1rem 3rem 0 3rem;
    }

    .blogs .blog-post-img {
        height: 264px;
    }

    .blog-post-detail-title {
        font-size: 22px;
    }


    .blogs .img-link-heading {
        font-size: 13px;
    }

    .blog-post .blog-detail {
        font-size: 16px;
    }

    .blog-list li {
        font-size: 14px;
    }

    .blog-container .blogs {
        margin-left: 20px;
    }

    .posted-blog {
        width: 49%;
        padding-bottom: 8px;
    }

    .blog-menu-img {
        width: 47%;
        height: 85px;
    }

    .blog-menu .blog-search-icon {
        top: 12px;
        font-size: 23px;
    }
}

@media(max-width:769px) {
    .blog-container .blog-menu {
        display: none;
    }

    .blog-container {
        flex-direction: column;
    }

    .blog-container .blog-menu {
        width: 100%;
        position: static;
    }

    .blog-container .blogs {
        width: 100%;
    }

    .blog-menu-img {
        width: 23%;
    }

    .about-blog-post {
        margin-bottom: 12px;
    }

    .blog-container .blog-menu {
        margin-bottom: 15px;
    }

    .blog-container .blogs {
        margin-left: 0px;
    }

    .accordian-container {
        display: block;
        padding: 0 3rem;

    }

    .accordian-search-container {
        position: relative;
    }

    .accordian-search-container .blog-search {
        width: 100%;
        padding: 10px;
        border: 0.5px solid rgb(240, 234, 234);
        margin-bottom: 15px;

    }

    .accordian-search-container .blog-search-icon {
        position: absolute;
        right: 11px;
        top: 10px;
        font-size: 27px;
        color: rgb(146, 145, 145);
    }

    .acoordion-section {
        margin-bottom: 15px;
    }

    .blogs .blog-post-img {
        height: 238px;
    }

    .card {
        width: 100% !important;
        padding: 0 !important;
    }
    .accordian-search-container{
        margin-top: 15px;
    }
}

@media(max-width: 426px) {

    .blog-container,
    .accordian-container {
        padding: 0px 20px;
    }

    .posted-blog {
        width: 100%;
    }

    .about-blog-post {
        text-align: justify;
    }

    .blog-menu-img {
        width: 44%;
    }

    .blog-post-detail-p p {
        margin-bottom: 0;
        margin: 0 6px;
    }
}