.contact-page-container {
    padding: 2rem 5rem 0 5rem;
}

.contact-us-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 156.52%;
    margin-bottom: 4px;
    letter-spacing: 0.005em;
    color: #004684;
}

.p-dropdown {
    background-color: hsla(0, 0%, 96%, 1);
    height: 50px!important;
    border: 0!important;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 30px;
}

.p-dropdown .p-dropdown-trigger{
    padding-right: 20px;
    width: 4rem;
}

.show_dealers .dealer_details{
    display: flex;
    justify-content: space-between;
}

.map_image{
    width:60%;
    margin : 80px auto;
}

.map_image img{
    width: 100%;
}


.show_dealers{
    background-color: hsla(0, 0%, 96%, 1);
    padding: 30px 15px;
}



@media(max-width : 1080px){
    .map_image{
        width:80%;
        margin : 50px auto 0;
    }
}

@media(max-width : 525px){
    .map_image{
        width:90%;
        margin : 30px auto 0;
    }
}







.contact-us-address {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156.52%;
    margin: 0;
    letter-spacing: 0.005em;
}

.contact-page-icon {
    color: #004684;
    font-size: 25px;
}

.icon-padding {
    padding: 0 30px;
    border-right: 1px solid black;
    height: 95px;
    display: flex;
    align-items: center;
}

.contact-email {

    text-decoration-line: underline;
}

.contact-number,
.contact-email {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    /* identical to box height */
}

.box-border {
    display: flex;
    align-items: center;
    border: 1px solid black;
    height: 95px;
    margin: 20px 0;
}

.address-padding {
    padding: 10px 20px;
}

.contact-us-address-container {
    width: 35%;
}

.contact-us-address-container .heading,
.business-enquiry-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
}

/* .contact-form {
    width: 60%;
} */

.form-container {
    box-sizing: border-box;
}

.address-padding a {
    color: black;
}

.form-container .form-input {
    background: hsla(0, 0%, 96%, 1);
    border: none;
    padding: 16px 30px;
    /* margin-bottom: 15px; */
    width: 100%;
    box-sizing: border-box;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #9C9C9C;
}

.text-area {
    width: 100%;
    background: #f2f3f4;
    border: none;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    line-height: 11px;

    color: #9C9C9C;
    padding: 20px;
    /* margin-bottom: 15px; */
}
.contact_field {
    margin-bottom : 30px;
}
.success_msg {
    margin: 10px 7.5px;
    padding: 10px;
    background: whitesmoke;
    color: #24c124;
    text-align: center;
}
.submit-btn-container {
    display: flex;
    justify-content: end;
}

.form-submit-btn {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    background: #004684;
    color: #FFFFFF;
    border-color: #004684;
    padding: 11px 34px;
    margin-top: 15px;
    border: none;
}

.form-submit-btn:hover {
    color: #004684;
    background: none;
    border: 1px solid #004684;
}

/* Media Query */
@media(max-width: 1024px) {
    .contact-page-container {
        padding: 2rem 3rem 0 3rem;
    }

    .icon-padding {
        padding: 0 20px;
    }

    .contact-us-address {
        font-size: 13px;
    }

    .contact-number,
    .contact-email {
        font-size: 13px;
    }
    .box-border, .icon-padding {
        height: 115px;
    }
}

@media(max-width:821px) {
    .contact-page-container {
        flex-direction: column;
    }

    .contact-form {
        width: 100%;
    }

    .contact-us-address-container {
        width: 100%;
    }
}

@media(max-width: 769px) {


    .form-container .form-input {
        font-size: 13px;
    }

    .address-padding {
        padding: 0px 20px;
    }

    .box-border {
        height: 80px;
    }

    .badge-container {
        right: -6px;
    }

    .quality-container {
        right: 0px;
        width: 379px;
    }

    .icon-padding {
        height: 80px;
    }

    .form-container .form-input {
        margin: 8px 0;
    }

    .text-area {
        margin-top: 8px;
    }
    .form-submit-btn:hover{
        color: white;
        background: #004684;
    }
}

@media(max-width: 426px) {
    .contact-page-container {
        padding: 32px 20px 0 20px;
    }

    .form-container .form-input {
        margin: 6px 0;
    }

    .text-area {
        margin-top: 9px;
    }

    .contact-us-address-container .heading,
    .business-enquiry-heading {
        font-size: 25px;
    }

    .box-border,
    .icon-padding {
        height: 84px;
    }

    .box-border {
        margin: 10px 0;
    }

    .contact-us-address {
        font-size: 12px;
    }

    .address-padding {
        padding: 10px;
    }

    .badge-container img {
        width: 65%;
    }

    .form-submit-btn {
        font-size: 12px;
        padding: 12px;
    }

    .contact-us-address {
        font-size: 10px;
    }

    .contact-us-heading {
        font-size: 13px;
    }

    .contact-number,
    .contact-email {
        font-size: 12px;
    }

    .text-area,
    .form-input {
        font-size: 12px;
    }

    .contact-us-address-container .heading,
    .business-enquiry-heading {
        font-size: 20px;
    }

}

@media(max-width: 376px) {
    .text-area {
        margin: 6px 0;
    }

    .form-submit-btn {
        margin-top: 5px;
    }
}

@media(max-width: 281px) {
    .contact-us-heading {
        font-size: 12px;
    }

    .contact-us-address,
    .contact-number,
    .contact-email {
        font-size: 10px;
    }

}