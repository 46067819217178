.career-page-container {
    padding: 2rem 5rem 0 5rem;
    background-color: white;
}

.career-choose-container {
    width: 35%;
}

.choose-us-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
}

.career-form {
    width: 55%;
}

.choose-us-detail {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 166.52%;
    letter-spacing: 0.005em;
    color: #575656;
}

.file-input {
    width: 100%;
    background: #f2f3f4;
    border: none;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #9C9C9C;
    padding: 20px;
    /* margin-bottom: 15px; */
}

.form-container {
    width: 100%;
    margin: 0;
}

.career-submit-btn {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    background: #004684;
    color: #FFFFFF;
    border-color: #004684;
    padding: 11px 34px;
    margin-top: 15px;
    border: none;
}

.career-submit-btn:hover {
    color: #004684;
    background: none;
    border: 1px solid #004684;
}

/* Media Query */
@media(max-width: 1024px) {
    .career-page-container {
        padding: 2rem 3rem 0 3rem;
    }

    .file-input,
    .form-container .form-input {
        margin: 12px 0 !important;
    }
}

@media(max-width: 769px) {
    .career-page-container {
        flex-direction: column;
    }

    .choose-us-detail {
        text-align: justify;
    }

    .career-choose-container {
        width: 100%;
    }

    .career-form {
        width: 100%;
    }

    .file-input {
        font-size: 13px;
    }

    .career-submit-btn:hover {
        color: white;
        background: #004684;
    }
}

@media(max-width: 426px) {
    .career-page-container {
        padding: 32px 20px 0 20px;
    }

    .choose-us-heading {
        font-size: 25px;
    }

    .career-submit-btn {
        padding: 13px 20px;
    }

    .form-container .form-input,
    .file-input {
        width: 100%;
        margin: 6px 0px !important;
    }

    .text-area {
        margin-top: 6px;
    }

    .career-submit-btn {
        margin-top: 13px;
    }

    .choose-us-detail {
        font-size: 13px;
        line-height: 149.52%;
    }
}

@media(max-width:376px) {

    .form-container .form-input,
    .file-input {
        padding: 14px;
    }
}

@media(max-width: 320px) {
    .career-submit-btn {
        padding: 10px 20px;
        font-size: 14px;
    }

    .choose-us-heading {
        font-size: 20px;
    }
}