.personal_detail_form {
    padding: 20px 50px;
    width: 100%;
}

.personal_detail_form .main_heading {
    margin: 0px !important;
}

.user_detail p {
    margin: 0;
}

.user_detail_div {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.image_container {
    height: auto;
    width: 10%;
}

.image_container img {
    height: 100%;
    width: 100%;
}

.personal_detail_form .dashboard_form {
    display: grid;
    /* grid-template-columns: 49% 49%; */
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    grid-gap: 19px;

}

.field_label {
    display: block;
}

.field_container {
    width: 100%;
    margin-left: auto;
}

.field_container input {
    width: 100%;
    color: #9C9C9C;
    font-size: 15px;
    padding: 16px;
    background: #F4F4F4;
    border: 0;
}

.field_container input:focus {
    outline: none;
    border-width: 0;
}

.update_container {
    text-align: end;
}

.personal_detail_form .update_btn {
    background: #004684;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding: 11px 30px;
    margin: 20px 0 20px 20px;
}

/* Manage address */
.second_section {
    margin: 20px 0;

}

.address_section {
    display: grid;
    /* grid-template-columns: 32% 32% 32%; */
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    grid-gap: 20px;
}

.second_section h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    margin-bottom: 23px;

}

.address_section .shipping-card {
    width: 100%;
    margin-left: auto;
    height: 240px;
    border: 1px solid #D9D9D9;
    padding: 12px;
}

.card_1 {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.address_section .new_address_icon {
    color: #575656;
    font-size: 15px;
    font-weight: 600;
    line-height: 156.523%;
    letter-spacing: 0.075px;
}

.add_address {
    text-align: center;
}

.address_section .deliver_div {
    text-align: end;
}

.address_section .deliver_btn {
    background: #004684;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding: 9px 11px;
}

/* .address_section .order_address {
    margin: 0;
} */

.card_2 h4 {
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 156.523%;
    letter-spacing: 0.1px;
}

.address_section .phone_number {
    color: #575656;
    font-size: 15px;
    line-height: 156.523%;
    letter-spacing: 0.075px;
}

.address_section .edit_button {
    color: #575656;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.075px;
    border: none;
    background-color: white;
}

.address_section .deliver_btn1 {
    color: #004684 !important;
    border: 1px solid #004684 !important;
    background: none !important;
}

@media (max-width: 426px) {
    .personal_detail_form {
        padding: 20px;
    }

    .address_section {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
        grid-gap: 20px;
    }

    .address_section .shipping-card {
        height: 209px;
    }

    .user_detail p {
        margin: 8px 0;
    }

    .image_container {
        width: 33%;
    }

    .personal_detail_form .update_btn {
        font-size: 13px;
        padding: 9px 25px;
    }

    .address_section .deliver_btn {
        font-size: 13px;
    }

    .card_2 h4 {
        font-size: 18px;
    }

    .address_section .order_address {
        margin: 6px 0;
    }

    .user_detail_div {
        margin-bottom: 20px;
    }

    .second_section h2 {
        margin-bottom: 18px;
    }
}

@media(max-width:321px){
    .address_section .order_address {
        font-size: 14px;
    } 
    .personal_detail_form .update_btn, .address_section .deliver_btn  {
       font-size: 11px;
    }
}